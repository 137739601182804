import React from 'react';
import { Link, graphql } from 'gatsby';

import {
    IntroHolder,
    TwoColumns,
    Sidebar,
    Reference,
    Content,
    IntroQuote,
    CenteredQuote,
} from '@bahaide/shared/components/InnerPage';

import InnerPage from '../../../../components/InnerPage';

const DerEinzelne = ({ data }) => (
    <InnerPage title="Der Einzelne" description="">
        <IntroHolder
            title="Der Einzelne"
            subtitle="Weiterentwicklung des Einzelnen durch den Dienst an der Gesellschaft"
            picture={data.introImage}
            breadcrumbs={{
                grandparent: 'Woran Bahá’í glauben',
                parent: 'Der Einzelne und die Gesellschaft',
                'current-item': 'Der Einzelne',
            }}
        >
            <IntroQuote href="/woran-bahai-glauben/der-einzelne-und-die-gesellschaft/der-einzelne/zitate">
                Was die Menschen am nötigsten brauchen, sind Zusammenarbeit und
                gegenseitige Hilfe.
            </IntroQuote>
        </IntroHolder>

        <TwoColumns>
            <Content>
                <p>
                    Jeder Einzelne kann einen Beitrag zum gesellschaftlichen
                    Leben leisten.
                </p>
                <div>
                    <p>
                        Dabei ergreift er Initiativen, nutzt Chancen, geht
                        Freundschaften ein, baut Beziehungen auf und schließt
                        sich mit anderen zum gemeinsamen Dienst zusammen.
                        ‘Abdu’l-Bahá schrieb:
                    </p>
                </div>
                <CenteredQuote referenceNumber="2">
                    […]Ehre und Würde des Einzelnen liegen darin, dass er vor
                    all den Massen der Weltbewohner zu einer Quelle des
                    gesellschaftlichen Wohles wird.
                </CenteredQuote>
                <div>
                    <p>
                        Um in der gegenwärtigen Zeit etwas Nachhaltiges
                        beitragen zu können, ist es hilfreich, dass der Einzelne
                        ein Ziel vor Augen hat, das ihm ermöglicht, seine
                        geistig-spirituelle und intellektuelle Entwicklung und
                        den Wandel der Gesellschaft zu fördern. Der Einzelne
                        nimmt durch sein Verhalten Einfluss auf sein Umfeld und
                        wird im Gegenzug durch gesellschaftliche Strukturen und
                        Prozesse geformt.
                    </p>
                </div>
                <div>
                    <p>
                        <img
                            alt=""
                            src={data.derEinzelne.childImageSharp.fluid.src}
                            className="aligncenter"
                        />
                    </p>
                </div>
                <div>
                    <p>
                        Jeder Mensch kann es sich zum Ziel machen, seine
                        innewohnenden Fähigkeiten zu entwickeln und gemeinsam
                        mit anderen für die Gesellschaft einzusetzen. „Denkt zu
                        allen Zeiten daran, wie ihr jedem Mitglied der
                        Menschheit einen Dienst erweisen könnt…“, sagte
                        ‘Abdu’l-Bahá. „Seid aufrichtig freundlich, nicht nur dem
                        Anschein nach. […] Er sollte jedem, dem er begegnet,
                        einen guten Dienst erweisen und ihm von Nutzen sein. Er
                        sollte jedermanns Charakter veredeln und den Gedanken
                        der Menschen eine neue Richtung geben.“³
                    </p>
                </div>
                <div>
                    <p>
                        Dieser Maßstab für die Rolle des Einzelnen bei der{' '}
                        <Link to="/gemeinsames-handeln/">
                            aktiven Unterstützung
                        </Link>{' '}
                        der Gesellschaft ist in der Tat nicht niedrig. Doch
                        Perfektion ist keine Voraussetzung. Jeder Mensch ist
                        dazu in der Lage, sich durch aufrichtiges Bemühen diesem
                        hohen Standard zu nähern. Auf einem gemeinsamen Weg des
                        Dienstes unterstützen wir uns gegenseitig. Durch eine
                        Haltung der Demut schätzen wir den Beitrag eines jeden
                        Menschen und vermeiden jede Art der Überheblichkeit.
                    </p>
                </div>
                <Reference>
                    <p>1. ‘Abdu’l-Bahá, Gedanken des Friedens</p>
                    <p>
                        2. ‘Abdu’l-Bahá, Das Geheimnis Göttlicher Kultur, S. 13
                    </p>
                    <p>3. ‘Abdu’l-Bahá, Briefe und Botschaften 1:7</p>
                </Reference>
            </Content>
            <Sidebar />
        </TwoColumns>
    </InnerPage>
);

export default DerEinzelne;

export const pageQuery = graphql`
    query {
        introImage: file(
            relativePath: { eq: "Kachel_Der-Einzelne-4-e1493812550764.jpg" }
        ) {
            ...fluidImage
        }
        derEinzelne: file(relativePath: { eq: "Der-Einzelne.m.m-1.png" }) {
            ...fluidImage
        }
    }
`;
